import * as React from "react"

import Layout from "../components/layout";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {useI18next} from "gatsby-plugin-react-i18next";
import {GatsbySeo} from "gatsby-plugin-next-seo";

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  image: file(name: {eq: "404-sushi"}) {
    id
    relativeDirectory
    relativePath
    childImageSharp {
      gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
    }
  }
}
`

function NotFoundSeo() {
	const {language} = useI18next();
	return (
		<GatsbySeo
			title={"404"}
			noindex={true}
			nofollow={true}
			language={language}
		/>
	)
}

export default function NotFoundPage({data}: any) {

	const image = getImage(data.image)
	const {t} = useI18next();

	return (
		<>
			<NotFoundSeo/>
			<Layout>
				<div className="layout-containerized mb-20">
					<div className="flex flex-col justify-center content-center items-center">
						<div className="my-12">
							<h1 className="text-3xl font-bold">404: Not Found</h1>
						</div>
						<div>
							{image && <GatsbyImage alt={"todo"} image={image}/>}
						</div>
						<div className="my-5">
							<p>{t("404.description")}</p>
						</div>
					</div>
				</div>
			</Layout>
		</>
	)
}
